import React, { useState } from 'react';
import { Box } from '@heycater/design-system';
import Image from 'next/image';

import Banner1 from '@images/homepage/Banner1.jpeg';
import Banner1Lowres from '@images/homepage/Banner1Lowres.jpeg';
import MobileBanner1 from '@images/homepage/MobileBanner1.jpeg';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function BannerImage1() {
  const mobile = useBreakpointDown('sm');
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Box>
      {mobile ? (
        <div style={{ position: 'relative', width: '100%', height: '188px' }}>
          <Image
            objectPosition="center"
            src={MobileBanner1}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            priority
            alt=""
          />
        </div>
      ) : (
        <Image
          src={loaded ? Banner1 : Banner1Lowres}
          quality={100}
          onLoadingComplete={handleLoad}
          placeholder="blur"
          objectFit="cover"
          alt=""
          blurDataURL={Banner1Lowres.src}
        />
      )}
    </Box>
  );
}
